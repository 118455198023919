import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
import {AmendmentType} from "../../../types/capitalBudgetEnums";
import {InputTypes} from "../../../types/InputTypes";
// MUI
import {Button, Grid} from "@mui/material";
import {checkDateBefore, checkDateSame, checkValidDate, formatDate} from "../../../utils/DateUtils";
// components
import {FormInput} from "../../../components";
// store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {addMaturityChange, removeMaturityChange} from "../../../store/capitalBudget/capitalBudgetSlice";
import {retrieveGroupedPortfolioWMatChanges} from "../../../store/capitalBudget/selectors/maturitySelector";
import {MaturityChanges} from "../../../types/forecastTypes";

// ----------------------------------------------------------------------
// Validation Schema
const LoanSchema = Yup.object().shape({
    amendment: Yup.string().required('Amendment is required.'),
    amendedMaturity: Yup.date()
        .nullable()
        .typeError('Valid Date Required')
});
// ----------------------------------------------------------------------

const formValues = {
    tranche: null,
    trancheId: '',
    amendment: '',
    originalMaturity: '',
    amendedMaturity: '',
    comments: ''
}

type MaturityChangeFormProps = {
    values: MaturityChanges | null,
    onClose: () => void
}

// Form for Maturity Changes
export default function MaturityChangeForm({values, onClose}: MaturityChangeFormProps) {
    const dispatch = useAppDispatch();

    const portfolio = useAppSelector(state => retrieveGroupedPortfolioWMatChanges(state));

    return (
        <>
            <Formik
                initialValues={
                    (values && values.id) ?
                        {
                            ...formValues,
                            comments: values.comments,
                            tranche: portfolio.find(p => values.trancheId === p.tranche_id)
                        }
                        :
                        formValues
                }
                validationSchema={LoanSchema}
                onSubmit={(newValues: any) => {
                    dispatch(addMaturityChange({
                        trancheId: newValues.trancheId,
                        amendment: newValues.amendment,
                        originalMaturity: formatDate(newValues.originalMaturity, 'yyyy-MM-dd'),
                        amendedMaturity: formatDate(newValues.amendedMaturity, 'yyyy-MM-dd'),
                        comments: newValues.comments,
                        ...(newValues.tranche.maturityChange ? {id: newValues.tranche.maturityChange.id} : {})
                    }));
                    onClose();
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        setFieldValue,
                        values,
                    } = props

                    if (values.tranche && values.tranche.tranche_id !== values.trancheId) {
                        setFieldValue('trancheId', values.tranche.tranche_id);
                        setFieldValue('originalMaturity', new Date(values.tranche.maturity));
                        if (values.tranche.maturityChange && values.tranche.maturityChange !== values.amendedMaturity) {
                            setFieldValue('amendedMaturity', new Date(values.tranche.maturityChange.amendedMaturity));
                        }
                    }
                    if (values.originalMaturity !== values.amendedMaturity) {
                        if (checkValidDate(new Date(values.amendedMaturity))) {
                            const amendment = (checkDateBefore(values.originalMaturity, new Date(values.amendedMaturity))) ?
                                AmendmentType.EXTENSION
                                :
                                (!checkDateSame(values.originalMaturity, new Date(values.amendedMaturity))) ?
                                    AmendmentType.EARLY_REPAYMENT
                                    :
                                    '';
                            if (values.amendment !== amendment) {
                                setFieldValue('amendment', amendment);
                            }
                        }
                    }

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 1}}>
                                    <FormInput
                                        id='tranche'
                                        label='Tranche'
                                        fieldType={InputTypes.SEARCH}
                                        values={portfolio}
                                        labelFunc={(option) => `${option.tranche_id} - ${option.borrower}`}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <FormInput
                                        id='amendment'
                                        label='Amendment'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 12, md: 12, lg: 12}}

                                        values={[
                                            {label: AmendmentType.EXTENSION, value: AmendmentType.EXTENSION},
                                            {
                                                label: AmendmentType.EARLY_REPAYMENT,
                                                value: AmendmentType.EARLY_REPAYMENT
                                            },
                                            {label: AmendmentType.COMMITMENT, value: AmendmentType.COMMITMENT},
                                        ]}
                                        disabled
                                    />
                                    <FormInput
                                        id='originalMaturity'
                                        label='Current Maturity'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        disabled
                                    />
                                    <FormInput
                                        id='amendedMaturity'
                                        label='Amended Maturity'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <FormInput
                                        id='comments'
                                        label='Comments'
                                        fieldType={InputTypes.TEXT_BOX}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                        size='small'
                                    />
                                    {values.tranche && values.tranche.maturityChange &&
                                        <Grid item xs={12} sx={{p: 1}}>
                                            <Button
                                                fullWidth
                                                color="error"
                                                variant="contained"
                                                size="large"
                                                onClick={() => {
                                                    dispatch(removeMaturityChange(values.tranche.maturityChange.id));
                                                    onClose();
                                                }}
                                            >
                                                Remove Maturity Change
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={() => onClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                {values.tranche?.id ?
                                                    'Update Amendment' : 'Add Amendment'
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )

}