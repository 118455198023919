// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {MaturityChanges} from "../../../types/forecastTypes";
import {checkDateSame} from "../../../utils/DateUtils";
import {checkObjectChanged} from "../../../utils/generalUtils";

// Reducer Related to Capital Budget - Maturity Changes
const maturityReducer: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Create new Maturity Change
    addMaturityChange: {
        prepare(newMaturity: any) {
            if (newMaturity.id) {
                return {
                    payload: {
                        ...newMaturity,
                    }
                }
            } else {
                return {
                    payload: {
                        ...newMaturity,
                        id: nanoid()
                    }
                }
            }
        },
        reducer(state, action: PayloadAction<MaturityChanges>) {
            if (state.forecastData) {
                const index = state.forecastData.maturityChanges.findIndex(mc => mc.trancheId === action.payload.trancheId && mc.status !== SaveStatus.REMOVED);
                if (index !== -1) {
                    if (state.forecastData.maturityChanges[index].status !== SaveStatus.NEW) {
                        let changed = false;
                        const existing = state.forecastData.maturityChanges[index];
                        if (!checkDateSame(existing.amendedMaturity, action.payload.amendedMaturity)) changed = true;
                        if (!changed && checkObjectChanged(existing, action.payload, ['amendment', 'comments'])) changed = true;
                        if (changed) {
                            action.payload.previous = (!existing.previous) ? existing : existing.previous;
                            action.payload.status = SaveStatus.EDITED;
                            state.forecastData.maturityChanges[index] = action.payload;
                            state.changes = true;
                        }
                    } else if (state.forecastData.maturityChanges[index].status === SaveStatus.NEW) {
                        action.payload.status = SaveStatus.NEW;
                        state.forecastData.maturityChanges[index] = action.payload;

                    }
                } else {
                    action.payload.status = SaveStatus.NEW;
                    state.forecastData.maturityChanges = [...state.forecastData.maturityChanges, action.payload]
                    state.changes = true;
                }
            }
        }
    },
    // REMOVE MATURITY CHANGES
    removeMaturityChange: (state, action: PayloadAction<number>) => {
        if (state.forecastData) {
            const index = state.forecastData.maturityChanges.findIndex(mc => mc.id === action.payload && mc.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.forecastData.maturityChanges[index].status !== SaveStatus.NEW) {
                    state.forecastData.maturityChanges[index].status = SaveStatus.REMOVED;
                } else {
                    const maturityChanges = state.forecastData.maturityChanges;
                    maturityChanges.splice(index, 1);
                    state.forecastData.maturityChanges = maturityChanges;
                }
                state.changes = true;
            }

        }
    },
    // REMOVE BULK AFTER CHECK
    removeMaturityChangeBulk: (state, action: PayloadAction<number[]>) => {
        action.payload.forEach(id => {
            if (state.forecastData) {
                const index = state.forecastData.maturityChanges.findIndex(mc => mc.id === id && mc.status !== SaveStatus.REMOVED);
                if (index !== -1) {
                    if (state.forecastData.maturityChanges[index].status !== SaveStatus.NEW) {
                        state.forecastData.maturityChanges[index].status = SaveStatus.REMOVED;
                    } else {
                        const maturityChanges = state.forecastData.maturityChanges;
                        maturityChanges.splice(index, 1);
                        state.forecastData.maturityChanges = maturityChanges;
                    }
                    state.changes = true;
                }
            }
        })
    },
    // BULK UPDATE MATURITY CHANGES
    updateMaturityChanges: (state, action: PayloadAction<Array<{id: number, attribute: keyof MaturityChanges, value: any}>>) => {
        action.payload.forEach(mc => {
            if (state.forecastData) {
                const index = state.forecastData.maturityChanges.findIndex(m => m.id === mc.id);
                if (index !== -1) {
                    const maturityChange = state.forecastData.maturityChanges[index];

                    state.forecastData.maturityChanges[index] = {
                        ...maturityChange,
                        status: SaveStatus.EDITED,
                        [mc.attribute]: mc.value
                    }
                }
            }
        })
    }
}

export default maturityReducer;