// Local imports
import {CalculationLoanType, ForecastPeriod} from "../../../types/capitalBudgetTypes";
import {sourcesAndUsesColumn} from "../../../store/capitalBudget/selectors/Reports/sourcesUseReport";
import {fCurrency} from "../../../utils/formatNumber";
import {checkInPeriod} from "../../../utils/DateUtils";
import {CapitalAction, InvestmentType, LoanTags, OtherTransactionTypes} from "../../../types/capitalBudgetEnums";
import {Capital, OtherTransaction} from "../../../types/forecastTypes";
import {DrillDownSection, DrillDownValue} from "../../../components";
// @MUI
import {Grid, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";

interface SourcesUsesDrillDownProps {
    forecastPeriod: ForecastPeriod,
    periodSourcesUses: sourcesAndUsesColumn
}

export const SourcesUsesDrillDown = ({forecastPeriod, periodSourcesUses}: SourcesUsesDrillDownProps) => {

    return (
        <>
            <Grid>
                <Table size="small">
                    <TableBody>
                        <TableRow sx={{bgcolor: 'info.dark'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Cash Opening
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((periodSourcesUses.cashBalanceClosing || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(periodSourcesUses.cashBalanceOpening || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{bgcolor: 'primary.main'}}>
                            <TableCell colSpan={4}>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'white'}}
                                >
                                    Sources
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <>
                            {/*Investor Inflows*/}
                            <DrillDownSection
                                header={'Investor Inflows'}
                                value={periodSourcesUses.subscriptions}
                                retrieveValues={() => {
                                    return forecastPeriod.capital.filter((c: Capital) =>
                                        c.transactionType === CapitalAction.SUBSCRIPTION).map((ct: Capital) => {
                                        return {
                                            id: ct.id,
                                            date: ct.date,
                                            name: ct.name,
                                            value: ct.amount
                                        }
                                    })
                                }}
                            />
                            {/*Net Loan Repayments*/}
                            <DrillDownSection
                                header={'Net Loan Repayments'}
                                value={periodSourcesUses.netRepayments}
                                retrieveValues={() => []}
                                noExpand={true}
                                sx={{
                                    borderBottom: 1
                                }}
                            />
                            {/*------------------------------------------------------------------------------------------*/}
                            <>
                                {/*Loan Repayments*/}
                                <DrillDownSection
                                    header={'Loan Repayments'}
                                    value={periodSourcesUses.expectedRepaymentsCash}
                                    retrieveValues={() => {
                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                            l.tags.includes(LoanTags.REPAYMENT)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.endDate,
                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                value: l.updatedDrawn,
                                                ...(l.amendedMaturity) ? {} : {sx: {bgcolor: 'info.light'}}
                                            }
                                        })
                                    }}
                                />
                                {/*Notified Early Repayments*/}
                                <DrillDownSection
                                    header={'Notified Early Repayments'}
                                    value={periodSourcesUses.earlyRepaymentsCash}
                                    retrieveValues={() => {
                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                            l.tags.includes(LoanTags.EARLY_REPAYMENT)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.amendedMaturity,
                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                value: l.updatedDrawn
                                            }
                                        })
                                    }}
                                />
                                {/*Extended Loan Repayments*/}
                                <DrillDownSection
                                    header={'Extended Loan Repayments'}
                                    value={periodSourcesUses.extensionCash}
                                    retrieveValues={() => {
                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                            l.tags.includes(LoanTags.EXTENSION)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.amendedMaturity,
                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                value: l.updatedDrawn
                                            }
                                        })
                                    }}
                                />
                                {/*Asset Sell Downs/Partial Repayments*/}
                                <DrillDownSection
                                    header={'Asset Sell Downs/Partial Repayments'}
                                    value={periodSourcesUses.selldownsCash}
                                    retrieveValues={() => forecastPeriod.book.filter((l: CalculationLoanType) =>
                                        l.tags.includes(LoanTags.SELLDOWN)).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                        loan.selldowns?.forEach(selldown => {
                                            if (checkInPeriod(selldown.date, forecastPeriod)) {
                                                values.push({
                                                    id: selldown.id,
                                                    date: selldown.date,
                                                    name: `${loan.trancheId} (${loan.fund}) - ${loan.name}`,
                                                    value: selldown.amount
                                                })
                                            }
                                        })

                                        return values;
                                    }, [])}
                                />
                                {/*Transfers Out*/}
                                <DrillDownSection
                                    header={'Transfers Out'}
                                    value={periodSourcesUses.transfersOutCash}
                                    retrieveValues={() => {
                                        return [
                                            ...forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                l.tags.includes(LoanTags.TRANSFER_OUT)).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                                loan.transfersOut?.forEach(transfer => {
                                                    if (checkInPeriod(transfer.transferDate, forecastPeriod)) {
                                                        values.push({
                                                            id: transfer.id,
                                                            date: transfer.transferDate,
                                                            name: `${loan.trancheId} (${loan.fund}) - ${loan.name}`,
                                                            value: transfer.amount
                                                        })
                                                    }
                                                })
                                                return values;
                                            }, []),
                                            ...forecastPeriod.otherTransactions.filter((t: OtherTransaction) => (t.transactionType === OtherTransactionTypes.FUND_TRANSFER && t.amount > 0))
                                                .map((t) => {
                                                    return {
                                                        id: t.id,
                                                        date: t.date,
                                                        name: t.notes,
                                                        value: t.amount
                                                    }
                                                })
                                        ]
                                    }}
                                />
                                {/*Transfers In Offset*/}
                                <DrillDownSection
                                    header={'Transfers In Offset'}
                                    value={periodSourcesUses.transfersInOffsetCash}
                                    retrieveValues={() => forecastPeriod.book.filter((l: CalculationLoanType) =>
                                        l.tags.includes(LoanTags.OFFSET_TRANSFER_IN)).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                        loan.transfersOut?.forEach(transfer => {
                                            if (checkInPeriod(transfer.transferDate, forecastPeriod)) {
                                                values.push({
                                                    id: transfer.id,
                                                    date: transfer.transferDate,
                                                    name: `${loan.trancheId} (${loan.fund}) - ${loan.name}`,
                                                    value: transfer.amount
                                                })
                                            }
                                        })
                                        return values;
                                    }, [])
                                    }
                                />
                            </>
                            {/*------------------------------------------------------------------------------------------*/}
                            {/*Required Debt Drawn/Asset Transfer*/}
                            <DrillDownSection
                                header={'Required Debt Drawn/Asset Transfer'}
                                value={periodSourcesUses.debtDraw}
                                retrieveValues={() => forecastPeriod.otherTransactions.filter((t: OtherTransaction) =>
                                    t.transactionType === OtherTransactionTypes.DEBT_DRAW).map((t) => {
                                    return {
                                        id: t.id,
                                        date: t.date,
                                        name: t.notes,
                                        value: t.amount
                                    }
                                })}
                                sx={{
                                    borderTop: 1
                                }}
                            />
                            {/*Other Adjustments*/}
                            <DrillDownSection
                                header={'Other Adjustments'}
                                value={periodSourcesUses.adjustmentsPos}
                                retrieveValues={() => forecastPeriod.otherTransactions.filter((t: OtherTransaction) =>
                                    t.transactionType === OtherTransactionTypes.ADJUSTMENT && t.amount > 0).map((t) => {
                                    return {
                                        id: t.id,
                                        date: t.date,
                                        name: t.notes,
                                        value: t.amount
                                    }
                                })}
                                sx={{
                                    borderTop: 1
                                }}
                            />
                        </>
                        <TableRow sx={{bgcolor: 'primary.main'}}>
                            <TableCell colSpan={4}>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'white'}}
                                >
                                    Uses
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <>
                            {/*New Loans (First Drawdown at FC)*/}
                            <DrillDownSection
                                header={'New Loans (First Drawdown at FC)'}
                                value={periodSourcesUses.firstDrawdown}
                                retrieveValues={() => {
                                    return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                        l.tags.includes(LoanTags.FIRST_DRAW)).map((l: CalculationLoanType) => {
                                        return {
                                            id: l.id,
                                            date: l.startDate,
                                            name: `${l.name}`,
                                            value: -l.updatedDrawn
                                        }
                                    })
                                }}
                            />
                            {/*Net Loan Repayments -ves*/}
                            <DrillDownSection
                                header={'Net Loan Repayments (Uses)'}
                                value={0}
                                retrieveValues={() => []}
                                noExpand={true}
                                sx={{
                                    borderBottom: 1
                                }}
                            />
                            {/*------------------------------------------------------------------------------------------*/}
                            <>
                                {/*IC Approved Extended Loans*/}
                                <DrillDownSection
                                    header={'IC Approved Extended Loans'}
                                    value={periodSourcesUses.extensionOffsetCash}
                                    retrieveValues={() => {
                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                            l.tags.includes(LoanTags.OFFSET_EXTENSION)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.endDate,
                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                value: -l.updatedDrawn
                                            }
                                        })
                                    }}
                                />
                                {/*Early Repayments Offset*/}
                                <DrillDownSection
                                    header={'Early Repayments Offset'}
                                    value={periodSourcesUses.earlyRepOffsetCash}
                                    retrieveValues={() => {
                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                            l.tags.includes(LoanTags.OFFSET_EARLY)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.endDate,
                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                value: -l.updatedDrawn
                                            }
                                        })
                                    }}
                                />
                                {/*Asset Sell Downs/Partial Repayments Offset*/}
                                <DrillDownSection
                                    header={'Asset Sell Downs/Partial Repayments Offset'}
                                    value={periodSourcesUses.selldownOffsetCash}
                                    retrieveValues={() => forecastPeriod.book.filter((l: CalculationLoanType) =>
                                        l.tags.includes(LoanTags.OFFSET_SELLDOWN)).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                        loan.selldowns?.forEach(selldown => {
                                            values.push({
                                                id: selldown.id,
                                                date: loan.endDate,
                                                name: `${loan.trancheId} (${loan.fund}) - ${loan.name}`,
                                                value: -selldown.amount
                                            })
                                        })
                                        return values;
                                    }, [])}
                                />
                                {/*Transfers In*/}
                                <DrillDownSection
                                    header={'Transfers In'}
                                    value={periodSourcesUses.transfersInCash}
                                    retrieveValues={() => {
                                        return [
                                            ...forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                l.tags.includes(LoanTags.TRANSFER_IN)).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                                loan.transfersIn?.forEach(transfer => {
                                                    if (checkInPeriod(transfer.transferDate, forecastPeriod)) {
                                                        values.push({
                                                            id: transfer.id,
                                                            date: transfer.transferDate,
                                                            name: `${loan.trancheId} (${loan.fund}) - ${loan.name}`,
                                                            value: -transfer.amount
                                                        })
                                                    }
                                                })
                                                return values;
                                            }, []),
                                            ...forecastPeriod.otherTransactions.filter((t: OtherTransaction) => (t.transactionType === OtherTransactionTypes.FUND_TRANSFER && t.amount < 0))
                                                .map((t) => {
                                                    return {
                                                        id: t.id,
                                                        date: t.date,
                                                        name: t.notes,
                                                        value: t.amount
                                                    }
                                                })
                                        ]
                                    }}
                                />
                                {/*Transfers Out Offset*/}
                                <DrillDownSection
                                    header={'Transfers Out Offset'}
                                    value={periodSourcesUses.transfersOutOffsetCash}
                                    retrieveValues={() => forecastPeriod.book.filter((l: CalculationLoanType) =>
                                        l.tags.includes(LoanTags.OFFSET_TRANSFER_OUT)).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                        loan.transfersOut?.forEach(transfer => {
                                            values.push({
                                                id: transfer.id,
                                                date: loan.endDate,
                                                name: `${loan.trancheId} (${loan.fund}) - ${loan.name}`,
                                                value: -transfer.amount
                                            })
                                        })
                                        return values;
                                    }, [])}
                                />
                            </>
                            {/*------------------------------------------------------------------------------------------*/}
                            {/*Investor Redemptions (Incl. 1 period buffer)*/}
                            <DrillDownSection
                                header={'Investor Redemptions (Incl. 1 period buffer)'}
                                value={periodSourcesUses.redemptionOffset}
                                retrieveValues={() => periodSourcesUses.redemptionOffsetArray.map((c: Capital) => ({
                                    id: c.id,
                                    date: c.date,
                                    name: c.name,
                                    value: -c.amount
                                }))}
                                sx={{
                                    borderTop: 1
                                }}
                            />
                            {/*CRE (CTC)*/}
                            <DrillDownSection
                                header={'CRE (CTC)'}
                                value={periodSourcesUses.creCTC}
                                retrieveValues={() => {
                                    return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                        l.tags.includes(LoanTags.CRE_CTC)).map((l: CalculationLoanType) => {
                                        return {
                                            id: l.id,
                                            date: null,
                                            name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                            value: -(l?.ctcDrawdown || 0)
                                        }
                                    })
                                }}
                            />
                            {/*Coporate (CTC)*/}
                            {/*<DrillDownSection*/}
                            {/*    header={'Coporate (CTC)'}*/}
                            {/*    value={periodSourcesUses.corpCTC}*/}
                            {/*    retrieveValues={() => {*/}
                            {/*        return forecastPeriod.book.filter((l: CalculationLoanType) =>*/}
                            {/*            l.tags.includes(LoanTags.CORP_CTC_NEW)).map((l: CalculationLoanType) => {*/}
                            {/*            return {*/}
                            {/*                id: l.id,*/}
                            {/*                date: null,*/}
                            {/*                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,*/}
                            {/*                value: -(l.ctcDrawdown || 0)*/}
                            {/*            }*/}
                            {/*        })*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*Cash Distribution Net of DRP*/}
                            <DrillDownSection
                                header={'Cash Distribution Net of DRP'}
                                value={periodSourcesUses.cashDistribution}
                                retrieveValues={() => forecastPeriod.otherTransactions.filter((t: OtherTransaction) =>
                                    t.transactionType === OtherTransactionTypes.CASH_DISTRIBUTION).map((t) => {
                                    return {
                                        id: t.id,
                                        date: t.date,
                                        name: t.notes,
                                        value: t.amount
                                    }
                                })}
                                sx={{
                                    borderTop: 1
                                }}
                            />
                            {/*Confirmed drawdowns from Revolvers*/}
                            <DrillDownSection
                                header={'Confirmed drawdowns from Revolvers'}
                                value={periodSourcesUses.revolverDrawdown}
                                retrieveValues={() => forecastPeriod.otherTransactions.filter((t: OtherTransaction) =>
                                    t.transactionType === OtherTransactionTypes.REVOLVER_DRAWDOWN).map((t) => {
                                    return {
                                        id: t.id,
                                        date: t.date,
                                        name: t.notes,
                                        value: t.amount
                                    }
                                })}
                                sx={{
                                    borderTop: 1
                                }}
                            />
                            {/*Other Adjustments*/}
                            <DrillDownSection
                                header={'Other Adjustments'}
                                value={periodSourcesUses.adjustmentsNeg}
                                retrieveValues={() => forecastPeriod.otherTransactions.filter((t: OtherTransaction) =>
                                    t.transactionType === OtherTransactionTypes.ADJUSTMENT && t.amount < 0).map((t) => {
                                    return {
                                        id: t.id,
                                        date: t.date,
                                        name: t.notes,
                                        value: t.amount
                                    }
                                })}
                                sx={{
                                    borderTop: 1
                                }}
                            />
                        </>
                        <TableRow sx={{bgcolor: 'info.lighter'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Net Sources and Uses
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{color: ((periodSourcesUses.total || 0) >= 0) ? 'black' : 'red'}}
                                >
                                    {fCurrency(periodSourcesUses.total || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{bgcolor: 'info.dark'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Cash Closing (assuming no RCF, Capex draws)
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((periodSourcesUses.cashBalanceClosing || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(periodSourcesUses.cashBalanceClosing || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{height: 25, border: 'none'}}>
                            <TableCell colSpan={4}>
                            </TableCell>
                        </TableRow>
                        {/*------------------------------------------------------------------------------------------*/}
                        {/*DEBT FACILITIES*/}
                        <>
                            <TableRow sx={{bgcolor: 'primary.main'}}>
                                <TableCell colSpan={4}>
                                    <Typography
                                        variant='h6'
                                        sx={{color: 'white'}}
                                    >
                                        Debt Facilities
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {periodSourcesUses.facilities.map(facility => {
                                return (
                                    <DrillDownSection
                                        key={facility.label}
                                        header={facility.label}
                                        value={facility.value}
                                        retrieveValues={() => facility.subValues.map((sv) => ({
                                            id: sv.label,
                                            date: null,
                                            name: sv.label,
                                            value: sv.value
                                        }))}

                                    />
                                )
                            })}
                            <DrillDownSection
                                header={'Changes in Bank Facilities (In Pipelines)'}
                                value={periodSourcesUses.newFacility}
                                retrieveValues={() => {
                                    return forecastPeriod.capital.filter((c: Capital) =>
                                        [CapitalAction.CANCELLATION, CapitalAction.COMMITMENT].includes(c.transactionType)).map((ct: Capital) => {
                                        return {
                                            id: ct.id,
                                            date: ct.date,
                                            name: ct.name,
                                            value: ct.amount
                                        }
                                    })
                                }}
                            />
                            <TableRow sx={{bgcolor: 'info.dark'}}>
                                <TableCell colSpan={3}>
                                    <Typography
                                        variant='h6'
                                    >
                                        Total Available Facility
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography
                                        variant='h6'
                                        sx={{...(((periodSourcesUses.totalAvaFacility || 0) < 0) ? {color: 'red'} : {})}}
                                    >
                                        {fCurrency(periodSourcesUses.totalAvaFacility || 0)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </>
                        <TableRow sx={{height: 25, border: 'none'}}>
                            <TableCell colSpan={4}>
                            </TableCell>
                        </TableRow>
                        {/*------------------------------------------------------------------------------------------*/}
                        {/*NET LIQUIDITY*/}
                        <>
                            <TableRow sx={{bgcolor: 'primary.main'}}>
                                <TableCell colSpan={4}>
                                    <Typography
                                        variant='h6'
                                        sx={{color: 'white'}}
                                    >
                                        Net liquidity
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {/*Revolvers*/}
                            <DrillDownSection
                                header={'Revolvers'}
                                value={periodSourcesUses.revolversUndrawn}
                                retrieveValues={() => {
                                    return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                        (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE && l.trancheType === 'Revolving')).map((l: CalculationLoanType) => {
                                        return {
                                            id: l.id,
                                            date: null,
                                            name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                            value: -l.updatedUndrawn
                                        }
                                    })
                                }}
                            />
                            {/*Capex*/}
                            <DrillDownSection
                                header={'Capex'}
                                value={periodSourcesUses.capexUndrawn}
                                retrieveValues={() => {
                                    return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                        (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE && l.trancheType === 'Capex')).map((l: CalculationLoanType) => {
                                        return {
                                            id: l.id,
                                            date: null,
                                            name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                            value: -l.updatedUndrawn
                                        }
                                    })
                                }}
                            />
                            {/*Other*/}
                            <DrillDownSection
                                header={'Other'}
                                value={periodSourcesUses.otherUndrawn}
                                retrieveValues={() => {
                                    return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                        (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE && l.trancheType !== 'Revolving' && l.trancheType !== 'Capex')).map((l: CalculationLoanType) => {
                                        return {
                                            id: l.id,
                                            date: null,
                                            name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                            value: -l.updatedUndrawn
                                        }
                                    })
                                }}
                            />
                            <TableRow sx={{bgcolor: 'info.dark'}}>
                                <TableCell colSpan={3}>
                                    <Typography
                                        variant='h6'
                                    >
                                        Undrawn Revolving Loan Commitments
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography
                                        variant='h6'
                                        sx={{color: ((periodSourcesUses.totalLiquidity || 0) >= 0) ? 'white' : 'red'}}
                                    >
                                        {fCurrency(periodSourcesUses.totalLiquidity || 0)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{height: 10, border: 'none'}}>
                                <TableCell colSpan={4}>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{bgcolor: 'primary.light'}}>
                                <TableCell colSpan={3}>
                                    <Typography
                                        variant='h6'
                                    >
                                        Net Committed Liquidity
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography
                                        variant='h6'
                                        sx={{color: ((periodSourcesUses.liquidityCommitment || 0) >= 0) ? 'white' : 'red'}}
                                    >
                                        {fCurrency(periodSourcesUses.liquidityCommitment || 0)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </>
                        <TableRow sx={{height: 25, border: 'none'}}>
                            <TableCell colSpan={4}>
                            </TableCell>
                        </TableRow>
                        {/*------------------------------------------------------------------------------------------*/}
                        <>
                            <TableRow sx={{bgcolor: 'info.lighter'}}>
                                <TableCell colSpan={4}>
                                    <Typography
                                        variant='h6'
                                    >
                                        Fund Finance (uncommited)
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {/*Fund Finance*/}
                            <DrillDownSection
                                header={'Fund Finance Facilities'}
                                value={periodSourcesUses.unusedFacilities}
                                retrieveValues={() => {
                                    let values: Array<DrillDownValue> = [];

                                    values = [...values, ...forecastPeriod.fundFinances.map((f) => {
                                        return {
                                            id: f.id,
                                            date: null,
                                            name: f.name,
                                            value: -f.unusedMaster
                                        }
                                    })];

                                    if (periodSourcesUses.manualFF) {
                                        values.push({
                                            id: 'manual',
                                            date: null,
                                            name: '(Manual Fund Finance Adjustment)',
                                            value: periodSourcesUses.manualFF
                                        })
                                    }

                                    return values;
                                }}
                            />
                            <TableRow sx={{bgcolor: 'primary.light'}}>
                                <TableCell colSpan={3}>
                                    <Typography
                                        variant='h6'
                                    >
                                        Net Total Liquidity
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography
                                        variant='h6'
                                        sx={{color: ((periodSourcesUses.netLiquidity || 0) >= 0) ? 'black' : 'red'}}
                                    >
                                        {fCurrency(periodSourcesUses.netLiquidity || 0)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{height: 25, border: 'none'}}>
                                <TableCell colSpan={4}>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{bgcolor: 'info.lighter'}}>
                                <TableCell colSpan={3}>
                                    <Typography
                                        variant='h6'
                                    >
                                        Balance of Cost To Complete
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography
                                        variant='h6'
                                        sx={{color: ((periodSourcesUses.creUndrawn || 0) >= 0) ? 'black' : 'red'}}
                                    >
                                        {fCurrency(periodSourcesUses.creUndrawn || 0)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{bgcolor: 'primary.light'}}>
                                <TableCell colSpan={3}>
                                    <Typography
                                        variant='h6'
                                    >
                                        Surplus/(Deficit)
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography
                                        variant='h6'
                                        sx={{color: ((periodSourcesUses.surplusDeficit || 0) >= 0) ? 'black' : 'red'}}
                                    >
                                        {fCurrency(periodSourcesUses.surplusDeficit || 0)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{height: 25, border: 'none'}}>
                                <TableCell colSpan={4}>
                                </TableCell>
                            </TableRow>
                        </>
                        {/*------------------------------------------------------------------------------------------*/}
                    </TableBody>
                </Table>

            </Grid>
        </>
    )
}