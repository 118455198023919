import _ from "lodash";
import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {FundDetails, fundHolderSelector, initialBalance} from "../generalSelectors";
import {HorizontalTableColumn} from "../../../../components";
import {ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {createFilteredPortfolioBook} from "../../../../utils/CapitalBudgetUtils";
import {CapitalAction, InvestmentType, LoanTags, OtherTransactionTypes} from "../../../../types/capitalBudgetEnums";
import {addArrayValues, addValues} from "../../../../utils/mathUtil";
import {checkInPeriod} from "../../../../utils/DateUtils";
import {getFacilitiesByFund} from "./facilitiesSelector";
import {SourcesUsesRows, sourcesUsesRowsSecond} from "./sourcesUsesRows";
import {Capital} from "../../../../types/forecastTypes";
import {retrieveUnusedFundFacility} from "../fundFinanceSelector";


export interface sourcesAndUsesColumn extends HorizontalTableColumn {
    cashBalanceOpening: number,
    // Sources
    subscriptions: number,
    expectedRepaymentsCash: number,
    earlyRepaymentsCash: number,
    extensionCash: number,
    selldownsCash: number,
    transfersOutCash: number,
    transfersInOffsetCash: number,
    debtDraw: number,
    adjustmentsPos: number,
    // Uses
    firstDrawdown: number,
    extensionOffsetCash: number,
    earlyRepOffsetCash: number,
    selldownOffsetCash: number,
    transferInCash: number,
    transfersOutOffsetCash: number,
    redemptions: number,
    redemptionsArray: Array<Capital>,
    redemptionOffset: number,
    redemptionOffsetArray: Array<Capital>,

    creCTC: number,
    // corpCTC: number,
    cashDistribution: number,
    revolverDrawdown: number,
    adjustmentsNeg: number,
    total: number,
    cashBalanceClosing: number,

    facilities: Array<{ label: string, value: number, subValues: Array<{label: string, value: number}> }>,
    newFacility: number,
    totalAvaFacility: number,
    revolversUndrawn: number,
    capexUndrawn: number,
    otherUndrawn: number,
    totalLiquidity: number,
    netLiquidity: number,
    unusedFacilities: number,
    netPosition: number
}

//RETRIEVES SOURCES AND USES DATA
export const sourcesUsesDataSelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget.budget,
    fundHolderSelector,
    initialBalance,
    retrieveUnusedFundFacility,
    (budget, fund, balance, unusedFundFacility) => {
        if (!budget) return null

        const base = extractSourcesUsesPeriod(budget.base, balance.cash, 0, [], 0, 0, fund);

        // Keeps Running Balance carried throughout each period
        let weeklyStartingCash = balance.cash;
        let weeklyRedemptionsPrevious = 0;
        let weeklyRedemptionsPreviousArray: Array<Capital> = [];
        let inheritWeekFundFacility = -unusedFundFacility;
        let inheritWeeklyBankFacility = 0;
        const weeks: Array<sourcesAndUsesColumn> = budget.weeks.map(w => {
            const result = extractSourcesUsesPeriod(w, weeklyStartingCash, weeklyRedemptionsPrevious, weeklyRedemptionsPreviousArray, inheritWeekFundFacility, inheritWeeklyBankFacility, fund);
            weeklyRedemptionsPrevious = result.redemptions;
            weeklyRedemptionsPreviousArray = result.redemptionsArray;
            weeklyStartingCash = result.cashBalanceClosing;
            inheritWeekFundFacility = result.unusedFacilities;
            inheritWeeklyBankFacility = result.newFacility;
            // Manual FF defines the manually adjusted portion of the FF Undrawn
            result.manualFF = addValues(result.unusedFacilities, unusedFundFacility);

            return result;
        })

        // Keeps Running Balance carried throughout each period
        let monthlyStartingCash = balance.cash;
        let monthlyRedemptionsPrevious = 0;
        let monthlyRedemptionsPreviousArray: Array<Capital> = [];
        let inheritMonthFundFacility = -unusedFundFacility;
        let inheritMonthlyBankFacility = 0;
        const months: Array<sourcesAndUsesColumn> = budget.months.map(m => {
            const result = extractSourcesUsesPeriod(m, monthlyStartingCash, monthlyRedemptionsPrevious, monthlyRedemptionsPreviousArray, inheritMonthFundFacility, inheritMonthlyBankFacility, fund);
            monthlyRedemptionsPrevious = result.redemptions;
            monthlyRedemptionsPreviousArray = result.redemptionsArray;
            monthlyStartingCash = result.cashBalanceClosing;
            inheritMonthFundFacility = result.unusedFacilities;
            inheritMonthlyBankFacility = result.newFacility;
            // Manual FF defines the manually adjusted portion of the FF Undrawn
            result.manualFF = addValues(result.unusedFacilities, unusedFundFacility);

            return result;
        })

        return {
            base,
            weeks,
            months
        }
    }
)

// RETRIEVES SOURCES AND USES REPORT
export const sourcesUsesReport = createSelector(
    sourcesUsesDataSelector,
    getFacilitiesByFund,
    (sourcesUsesData, facilities) => {
        if (!sourcesUsesData) return {
            rows: SourcesUsesRows,
            data: []
        }

        let {
            weeks,
            months
        } = sourcesUsesData;

        let {
            openingFacility,
            facilityRows,
            facilitiesAvailable
        } = facilities

        const facilitiesArray = facilityRows.map(facilityType => {
            return {
                label: facilityType.label,
                value: openingFacility[facilityType.id as string],
                subValues: facilityType.subRows.map(facility => {
                    return (
                        {
                            label: facility.label,
                            value: openingFacility[facility.id as string]
                        }
                    )
                })
            }
        })

        for (let w in weeks) {
            const totalAvaFacility = addValues(weeks[w].totalAvaFacility, facilitiesAvailable);
            const liquidityCommitment = addValues(weeks[w].liquidityCommitment, totalAvaFacility);
            const netLiquidity = addValues(liquidityCommitment, weeks[w].unusedFacilities);
            const surplusDeficit = addValues(netLiquidity, weeks[w].creUndrawn);
            weeks[w] = {
                ...weeks[w],
                ...openingFacility,
                facilities: facilitiesArray,
                totalAvaFacility,
                liquidityCommitment,
                netLiquidity,
                surplusDeficit
            }
        }

        for (let m in months) {
            const totalAvaFacility = addValues(months[m].totalAvaFacility, facilitiesAvailable);
            const liquidityCommitment = addValues(months[m].liquidityCommitment, totalAvaFacility);
            const netLiquidity = addValues(liquidityCommitment, months[m].unusedFacilities);
            const surplusDeficit = addValues(netLiquidity, months[m].creUndrawn);
            months[m] = {
                ...months[m],
                ...openingFacility,
                facilities: facilitiesArray,
                totalAvaFacility,
                liquidityCommitment,
                netLiquidity,
                surplusDeficit
            }
        }

        weeks[0].sx = {
            ...weeks[0],
            sx: {borderLeft: 2, borderLeftColor: 'primary.main'},
            ...openingFacility,
            facilitiesAvailable
        }

        months[0] = {
            ...months[0],
            sx: {borderLeft: 2, borderLeftColor: 'primary.main'},
            ...openingFacility,
            facilitiesAvailable
        }

        let sourceUsesRows = _.cloneDeep(SourcesUsesRows);

        sourceUsesRows[sourceUsesRows.length - 1].subRows = [...facilityRows, ...sourceUsesRows[sourceUsesRows.length - 1]?.subRows || []]

        return {
            data: [...weeks, ...months] as Array<sourcesAndUsesColumn>,
            rows: [...sourceUsesRows, ...sourcesUsesRowsSecond]
        }
    }
)

// Extract necessary data from period for sources and uses
function extractSourcesUsesPeriod(period: ForecastPeriod, startingAvailableCash: number, previousRedemptions: number, previousRedemptionsArray: Array<Capital>, unusedFacilities: number, bankFacilitiesChanges: number, fund: FundDetails | null): sourcesAndUsesColumn {
    let results: any = {
        cashBalanceOpening: startingAvailableCash,

        subscriptions: 0,
        expectedRepaymentsCash: 0,
        earlyRepaymentsCash: 0,
        extensionCash: 0,
        selldownsCash: 0,
        transfersOutCash: 0,
        transfersInOffsetCash: 0,
        transfersInCash: 0,
        cashInterest: 0,
        debtDraw: 0,
        adjustmentsPos: 0,

        firstDrawdown: 0,
        extensionOffsetCash: 0,
        earlyRepOffsetCash: 0,
        selldownOffsetCash: 0,
        transferInCash: 0,
        transfersOutOffsetCash: 0,
        redemptions: 0,
        redemptionsArray: [],
        redemptionOffset: previousRedemptions,
        redemptionOffsetArray: previousRedemptionsArray,

        creCTC: 0,
        // corpCTC: 0,
        cashDistribution: 0,
        revolverDrawdown: 0,
        adjustmentsNeg: 0,
        total: 0,
        cashBalanceClosing: 0,

        newFacility: bankFacilitiesChanges,
        totalAvaFacility: 0,
        revolversUndrawn: 0,
        capexUndrawn: 0,
        otherUndrawn: 0,
        totalLiquidity: 0,
        netLiquidity: 0,
        unusedFacilities: unusedFacilities,

        creUndrawn: 0,
        netPosition: 0
    }

    // FILTER BOOK FOR THOSE ACTIVE OR IF SELECTED FUND
    const filteredBook = createFilteredPortfolioBook(period.book, fund, false);

    // filter loans and extract necessary information;
    filteredBook.forEach(loan => {

        loan.tags.forEach(tag => {
            switch (tag) {
                case LoanTags.REPAYMENT:
                    results.expectedRepaymentsCash = addValues(results.expectedRepaymentsCash, loan.updatedDrawn);
                    break;

                case LoanTags.EARLY_REPAYMENT:
                    results.earlyRepaymentsCash = addValues(results.earlyRepaymentsCash, loan.updatedDrawn);
                    break;

                case LoanTags.EXTENSION:
                    results.extensionCash = addValues(results.extensionCash, loan.updatedDrawn);
                    break;

                case LoanTags.SELLDOWN:
                    const selldown = (loan.selldowns || []).reduce((total, s) => {
                        if (checkInPeriod(s.date, period)) {
                            total = addValues(total, s.amount);
                        }
                        return total;
                    }, 0);
                    results.selldownsCash = addValues(results.selldownsCash, selldown || 0);
                    break;

                case LoanTags.TRANSFER_OUT:
                    const transferOut = (loan.transfersOut || []).reduce((total, t) => {
                        if (checkInPeriod(t.transferDate, period)) {
                            total = addValues(total, t.amount);
                        }
                        return total;
                    }, 0);
                    results.transfersOutCash = addValues(results.transfersOutCash, transferOut);
                    break;

                case LoanTags.OFFSET_TRANSFER_IN:
                    const transfersInOffset = (loan.transfersIn || []).reduce((total, t) => {
                        total = addValues(total, t.amount);
                        return total;
                    }, 0);
                    results.transfersInOffsetCash = addValues(results.transfersInOffsetCash, transfersInOffset)
                    break;

                case LoanTags.FIRST_DRAW:
                    results.firstDrawdown = addValues(results.firstDrawdown, -loan.updatedDrawn);
                    break;

                case LoanTags.OFFSET_EXTENSION:
                    results.extensionOffsetCash = addValues(results.extensionOffsetCash, -loan.updatedDrawn);
                    break;

                case LoanTags.OFFSET_EARLY:
                    results.earlyRepOffsetCash = addValues(results.earlyRepOffsetCash, -loan.updatedDrawn);
                    break;

                case LoanTags.OFFSET_SELLDOWN:
                    const selldownOffset = (loan.selldowns || []).reduce((total, s) => {
                        total = addValues(total, s.amount);
                        return total;
                    }, 0);
                    results.selldownOffsetCash = addValues(results.selldownOffsetCash, -selldownOffset);
                    break;

                case LoanTags.TRANSFER_IN:
                    const transferIn = (loan.transfersIn || []).reduce((total, t) => {
                        if (checkInPeriod(t.transferDate, period)) {
                            total = addValues(total, t.amount);
                        }
                        return total;
                    }, 0);
                    results.transfersInCash = addValues(results.transfersInCash, -transferIn);
                    break;

                case LoanTags.OFFSET_TRANSFER_OUT:
                    const transfersOutOffset = (loan.transfersOut || []).reduce((total, t) => {
                        total = addValues(total, t.amount);
                        return total;
                    }, 0);
                    results.transfersOutOffsetCash = addValues(results.transfersOutOffsetCash, -transfersOutOffset)
                    break;

                case LoanTags.CRE_CTC:
                    results.creCTC = addValues(results.creCTC, -(loan.ctcDrawdown || 0))
                    break;

                // case LoanTags.CORP_CTC_NEW:
                //     results.corpCTC = addValues(results.corpCTC, -(loan.ctcDrawdown || 0))
                //     break;

                default:
                    break;
            }

        })

        if (loan.tags.includes(LoanTags.ACTIVE) && loan.investmentType === InvestmentType.REAL_ESTATE) results.creUndrawn = addValues(results.creUndrawn, -loan.updatedUndrawn);

        if (loan.tags.includes(LoanTags.ACTIVE) && loan.investmentType !== InvestmentType.REAL_ESTATE) {
            switch (loan.trancheType) {
                case 'Revolving':
                    results.revolversUndrawn = addValues(results.revolversUndrawn, -loan.updatedUndrawn);
                    break;

                case 'Capex':
                    results.capexUndrawn = addValues(results.capexUndrawn, -loan.updatedUndrawn);
                    break;

                default:
                    results.otherUndrawn = addValues(results.otherUndrawn, -loan.updatedUndrawn);
                    break;
            }
        }
    })

    // Include Other Transactions
    period.otherTransactions.forEach(ot => {
        if (!fund || (fund && fund.label === ot.fund)) {
            switch (ot.transactionType) {
                case OtherTransactionTypes.ADJUSTMENT:
                    if (ot.amount > 0) {
                        results.adjustmentsPos = addValues(results.adjustmentsPos, ot.amount)
                    } else {
                        results.adjustmentsNeg = addValues(results.adjustmentsNeg, ot.amount)
                    }
                    break;

                case OtherTransactionTypes.CASH_DISTRIBUTION:
                    results.cashDistribution = addValues(results.cashDistribution, -ot.amount);
                    break;

                case OtherTransactionTypes.DEBT_DRAW:
                    results.debtDraw = addValues(results.debtDraw, ot.amount);
                    break;

                // case OtherTransactionTypes.INCREASED_BANK_FACILITIES:
                //     results.newFacility = addValues(results.newFacility, ot.amount);
                //     results.totalAvaFacility = addValues(results.totalAvaFacility, ot.amount);
                //     break;

                case OtherTransactionTypes.FUND_FACILITIES_UNDRAWN:
                    results.unusedFacilities = addValues(results.unusedFacilities, -ot.amount);
                    break;

                case OtherTransactionTypes.REVOLVER_DRAWDOWN:
                    results.revolverDrawdown = addValues(results.revolverDrawdown, -ot.amount);
                    results.revolversUndrawn = addValues(results.revolversUndrawn, ot.amount)
                    break;

                case OtherTransactionTypes.FUND_TRANSFER:
                    if (ot.amount < 0) {
                        results.transfersInCash = addValues(results.transfersInCash, ot.amount);
                    }
                    if (ot.amount > 0) {
                        results.transfersOutCash = addValues(results.transfersOutCash, ot.amount);
                    }
                    break;

                default:
                    break;
            }
        }
    })

    period.capital.forEach(c => {
        let amount = 0;
        if (fund) {
            if (fund.label === c.fund) {
                amount = c.amount;
            } else {
                const portion = fund.inheritance.get(c.fund);
                if (portion) {
                    amount = c.amount * portion;
                }
            }
        } else {
            amount = c.amount
        }
        switch (c.transactionType) {
            case CapitalAction.SUBSCRIPTION:
                results.subscriptions = addValues(results.subscriptions, amount);
                break;

            case CapitalAction.REDEMPTION:
                results.redemptions = addValues(results.redemptions, amount);
                results.redemptionsArray.push({
                    ...c,
                    amount
                })
                break;

            case CapitalAction.COMMITMENT:
                results.newFacility = addValues(results.newFacility, amount);
                break;

            case CapitalAction.CANCELLATION:
                results.newFacility = addValues(results.newFacility, amount);
                break;

            default:
                break;
        }
    })

    results.totalAvaFacility = addValues(results.totalAvaFacility, results.newFacility);

    const netRepayments = addArrayValues([
        results.expectedRepaymentsCash, // Loan Repayments
        results.earlyRepaymentsCash, // Notified Early Repayments
        results.extensionCash, // Extended Loan Repayments
        results.selldownsCash, // Asset Sell Downs/Partial Repayments
        results.transfersOutCash, // Transfers Out
        results.transfersInOffsetCash, // Transfers In Offset

        results.extensionOffsetCash, // IC Approved Extended Loans
        results.earlyRepOffsetCash, // Early Repayments Offset
        results.selldownOffsetCash, // Asset Sell Downs/Partial Repayments Offset
        results.transfersInCash, // Trnsfers In
        results.transfersOutOffsetCash // Transfers Out Offset
    ]);

    const netSourcesUses = addArrayValues([
        results.subscriptions, // Investor Inflows

        netRepayments, // Net Loan Repayments

        results.debtDraw, // Required Debt Drawn/Asset Transfer
        results.adjustmentsPos, // Other Adjustments

        results.firstDrawdown, // New Loans

        results.redemptionOffset, //Investor Redemptions (Include 1 Period Buffer)
        results.creCTC, // CRE CTC
        // results.corpCTC, // Corporate CTC
        results.cashDistribution, // Cash Distribution Net of DRP
        results.revolverDrawdown, // Confirmed Drawdowns from Revolvers
        results.adjustmentsNeg // Adjustments
    ]);

    const cashBalanceClosing = addValues(netSourcesUses, startingAvailableCash);

    const totalLiquidity = addArrayValues([
        results.revolversUndrawn,
        results.capexUndrawn,
        results.otherUndrawn
    ])

    const liquidityCommitment = addArrayValues([cashBalanceClosing, totalLiquidity]); // Net Committed Liquidity

    return {
        ...results,

        netRepayments,
        total: netSourcesUses,
        cashBalanceClosing,

        totalLiquidity,
        liquidityCommitment,

        label: period.label,
        labelTwo: period.labelTwo,
        tooltip: period.tooltip,
    }
}