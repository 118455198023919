import {memo} from "react";
import {Box} from "@mui/material";
import LoansDrilldownTable from "./LoansDrilldownTable";
import {DataTable, valueCurrency, valueDate} from "../../../../components";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {LoanTags, OtherTransactionTypes} from "../../../../types/capitalBudgetEnums";
import {checkInPeriod} from "../../../../utils/DateUtils";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";
import {generalRow} from "../../../../components/DataTable/DataTableUtils";

interface RepaymentsDrilldownProps {
    period: ForecastPeriod,
    fund: FundDetails | null,
}

function RepaymentsDrilldown({period, fund}: RepaymentsDrilldownProps) {

    const lookthrough = !!fund?.holdMap;

    const selldowns = period.book.filter((l: CalculationLoanType) =>
        l.tags.includes(LoanTags.SELLDOWN)).reduce((values: Array<any>, loan: CalculationLoanType) => {
        loan.selldowns?.forEach(selldown => {
            if (checkInPeriod(selldown.date, period)) {
                let value = selldown.amount;

                if (!fund || (fund.label === loan.fund || fund.holdMap)) {

                    if (fund && fund.label !== loan.fund) {
                        const portion = fund.holdMap?.get(loan.fund)
                        if (portion) {
                            value = portion as number * value
                        } else {
                            return values;
                        }
                    }

                    values.push({
                        id: selldown.id,
                        trancheId: loan.trancheId || '',
                        borrower: loan.name,
                        tranche: loan.tranche,
                        date: selldown.date,
                        value: value,
                        fund: loan.fund
                    })
                }
            }
        })
        return values;
    }, []);

    const transfers = period.book.filter((l: CalculationLoanType) =>
        l.tags.includes(LoanTags.TRANSFER_IN) || l.tags.includes(LoanTags.TRANSFER_OUT)
    ).reduce((values: Array<any>, loan: CalculationLoanType) => {
        loan.transfersIn?.forEach(transfer => {
            if (checkInPeriod(transfer.transferDate, period)) {
                let value = transfer.amount;

                if (fund && fund.holdMap) {
                    if (fund.holdMap.get(loan.fund)) {
                        value = fund.holdMap.get(loan.fund) as number * value
                    }
                }

                values.push({
                    id: transfer.id + 'in',
                    date: transfer.transferDate,
                    descr: `${transfer.fromFund} > ${transfer.toFund}`,
                    value: -value,

                    trancheId: loan.trancheId || '',
                    borrower: loan.name,
                    tranche: loan.tranche,
                    toFund: transfer.toFund,
                    fund: loan.fund
                })

            }
        })
        loan.transfersOut?.forEach(transfer => {
            if (checkInPeriod(transfer.transferDate, period)) {
                let value = transfer.amount;

                if (fund && fund.holdMap) {
                    if (fund.holdMap.get(loan.fund)) {
                        value = fund.holdMap.get(loan.fund) as number * value
                    }
                }
                values.push({
                    id: transfer.id + 'out',
                    date: transfer.transferDate,
                    descr: `${transfer.fromFund} > ${transfer.toFund}`,
                    value: value,

                    trancheId: loan.trancheId || '',
                    borrower: loan.name,
                    tranche: loan.tranche,
                    toFund: transfer.toFund,
                    fund: loan.fund
                })

            }
        })

        return values;
    }, []);

    period.otherTransactions.filter(t => t.transactionType === OtherTransactionTypes.FUND_TRANSFER)
        .forEach(transfer => {
            transfers.push({
                id: transfer.id,
                date: transfer.date,
                descr: transfer.notes,
                value: transfer.amount
            })
        })

    return (
        <>
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', minHeight: 800}}>
                <Box sx={{py: 1, flex: 1, minHeight: 200}}>
                    <LoansDrilldownTable
                        title={'Loan Repayments'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'value',
                                headerName: 'Commitment',
                                ...valueCurrency,
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => ((loan.tags.includes(LoanTags.REPAYMENT) && !loan.amendedMaturity) || loan.tags.includes(LoanTags.EXTENSION) || loan.tags.includes(LoanTags.EARLY_REPAYMENT))}
                    />
                </Box>
                {/*NEED SELLDOWNS PARTIAL REPAYMENTS*/}
                {selldowns.length > 0 &&
                    <Box sx={{pb: 1, flex: 0.5, minHeight: 100}}>
                        <DataTable
                            title={'Selldowns and Partial Repayments'}
                            columns={[
                                {
                                    field: 'date',
                                    headerName: 'Date',
                                    ...valueDate,
                                    width: 100,
                                },
                                {field: 'trancheId', headerName: 'Tranche ID', width: 100, ...generalRow},
                                ...(lookthrough) ? [
                                    {
                                        field: 'fund',
                                        headerName: 'Inherited',
                                        width: 90,
                                        sortable: false,
                                    }
                                ] : [],
                                {
                                    field: 'borrower',
                                    headerName: 'Borrower',
                                    flex: 1,
                                    minWidth: 300,
                                    ...generalRow
                                },
                                {
                                    field: 'tranche',
                                    headerName: 'Tranche',
                                    minWidth: 150,
                                    flex: 0.5,
                                    ...generalRow
                                },
                                {
                                    field: 'value',
                                    headerName: 'Amount',
                                    ...valueCurrency,
                                    width: 120
                                }

                            ]}
                            data={selldowns}
                            loading={false}
                            showExport={false}
                            search
                            sort={{field: 'maturity', sort: 'asc'}}
                        />
                    </Box>
                }
                {/*TRANSFERS IN AND OUT*/}
                {fund && transfers.length > 0 &&
                    <Box sx={{pb: 1, flex: 0.5, minHeight: 100}}>
                        <DataTable
                            title={'Transfers'}
                            columns={[
                                {
                                    field: 'date',
                                    headerName: 'Date',
                                    ...valueDate,
                                    width: 100,
                                },
                                {field: 'trancheId', headerName: 'Tranche ID', width: 100, ...generalRow},
                                // {
                                //     field: 'fund',
                                //     headerName: 'From',
                                //     width: 90,
                                //     sortable: false,
                                // },
                                // {
                                //     field: 'toFund',
                                //     headerName: 'To',
                                //     width: 90,
                                //     sortable: false,
                                // },
                                {
                                    field: 'descr',
                                    headerName: 'Description',
                                    flex: 0.5,
                                    ...generalRow,
                                },
                                {
                                    field: 'borrower',
                                    headerName: 'Borrower',
                                    flex: 1,
                                    minWidth: 300,
                                    ...generalRow
                                },
                                {
                                    field: 'tranche',
                                    headerName: 'Tranche',
                                    minWidth: 150,
                                    flex: 0.5,
                                    ...generalRow
                                },
                                {
                                    field: 'value',
                                    headerName: 'Amount',
                                    ...valueCurrency,
                                    width: 120
                                }

                            ]}
                            data={transfers}
                            loading={false}
                            showExport={false}
                            search
                            sort={{field: 'maturity', sort: 'asc'}}
                        />
                    </Box>
                }
            </Box>
        </>
    );
}

export default memo(RepaymentsDrilldown);